<template>
  <div>
    <p class="c21 title">
      <span>CONDITIONS D&rsquo;ABONNEMENT AUX SERVICES </span
      ><span class="c24">dok.ma</span><span>&nbsp;</span
      ><span class="c61">AVRIL 2021</span>
    </p>
    <p class="c114">
      <span class="c88 c123"
        >Les Conditions d&rsquo;Abonnement suivantes sont applicables &agrave;
        compter du 1er </span
      ><span class="c88">avril 2021</span>
    </p>
    <p class="c25 c124"><span class="c76 c109"></span></p>
    <p class="c119 c25"><span class="c57"></span></p>
    <ol class="c9 lst-kix_list_5-0 start" start="1">
      <li class="c16 c39">
        <h1 style="display:inline"><span>OBJET</span></h1>
      </li>
    </ol>
    <p class="c63">
      <span class="c3"
        >Les pr&eacute;sentes Conditions d&rsquo;Abonnement (ci-apr&egrave;s
        nomm&eacute;es</span
      >
    </p>
    <p class="c103">
      <span class="c4"
        >&laquo;CA&raquo;) ont pour objet de d&eacute;finir (i) les conditions
        d&rsquo;abonnement aux Services </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;et (ii) les droits et obligations de chaque Partie dans le cadre
        de l&rsquo;Abonnement.</span
      >
    </p>
    <p class="c25 c103"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="2">
      <li class="c16 c39">
        <h1 style="display:inline"><span>D&Eacute;FINITIONS</span></h1>
      </li>
    </ol>
    <p class="c0">
      <span class="c3"
        >Tous les termes en majuscules qui ne sont pas d&eacute;finis dans les
        pr&eacute;sentes CA auront la signification qui leur est donn&eacute;e
        dans la table des d&eacute;finitions en annexe.</span
      >
    </p>
    <p class="c0 c25"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="3">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span>MODALIT&Eacute;S D&rsquo;ABONNEMENT</span>
        </h1>
      </li>
    </ol>
    <p class="c98 c85">
      <span class="c4"
        >Le Service d&rsquo;Agenda et de dossier m&eacute;dical partag&eacute;,
        ainsi que le suivi m&eacute;dical sont les services principaux de</span
      ><span class="c24 c13 c51">&nbsp;</span><span class="c24 c13">dok.ma</span
      ><span class="c3"
        >. D&egrave;s lors, l&rsquo;Utilisateur reconna&icirc;t et accepte que
        toute souscription &agrave; des Services Suppl&eacute;mentaires
        n&eacute;cessite au pr&eacute;alable un Abonnement valide au Service
        d&rsquo;Agenda.</span
      >
    </p>
    <p class="c60">
      <span class="c3">L&rsquo;Abonn&eacute; reconna&icirc;t :</span>
    </p>
    <ul class="c9 lst-kix_list_4-0 start">
      <li class="c39 c58">
        <span class="c4"
          >avoir v&eacute;rifi&eacute; l&rsquo;ad&eacute;quation des Services
          &agrave; ses besoins et le cas &eacute;ch&eacute;ant, &agrave; ceux
          des Utilisateurs et Patients, et avoir re&ccedil;u de </span
        ><span class="c13">dok.ma</span
        ><span class="c3"
          >&nbsp;toutes les informations et conseils n&eacute;cessaires pour
          souscrire au Contrat en connaissance de cause ; et</span
        >
      </li>
      <li class="c39 c73">
        <span class="c4"
          >que sa d&eacute;cision de souscrire un Contrat n&rsquo;a pas
          &eacute;t&eacute; prise en consid&eacute;ration de la mise &agrave;
          disposition par </span
        ><span class="c13">dok.ma</span
        ><span class="c4"
          >&nbsp;d&rsquo;une quelconque fonctionnalit&eacute; ou
          caract&eacute;ristique future des Services </span
        ><span class="c13">dok.ma</span
        ><span class="c4"
          >, d&rsquo;&eacute;ventuelles communications publiques ou
          promotionnelles &eacute;manant de </span
        ><span class="c13">dok.ma</span
        ><span class="c4"
          >, ou de strat&eacute;gie de d&eacute;veloppement de produits.</span
        >
      </li>
    </ul>
    <p class="c73 c25"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="4">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span>INFORMATIONS RELATIVES &Agrave; L&rsquo;ABONN&Eacute;</span>
        </h1>
      </li>
    </ol>
    <p class="c44">
      <span class="c4"
        >Au cours de l&rsquo;Abonnement, et tout au long de leur relation
        contractuelle, </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;peut solliciter l&rsquo;Abonn&eacute; afin qu&rsquo;il fournisse
        les documents suivants :</span
      >
    </p>
    <p class="c80">
      <span class="c4"
        >pour les Abonn&eacute;s personnes physiques exer&ccedil;ant une
        activit&eacute; lib&eacute;rale : (i) copie d&rsquo;une pi&egrave;ce
        d&rsquo;identit&eacute; officielle en cours de validit&eacute; ; (ii)
        certificat d&rsquo;Inscription au Registre de Commerce, son ICE, et son
        RC ; (iii) tout dipl&ocirc;me d&rsquo;Etat justifiant d&rsquo;un titre,
        d&rsquo;une qualit&eacute; ou d&rsquo;une sp&eacute;cialisation dont
        l&rsquo;Abonn&eacute; souhaite se pr&eacute;valoir ou faire valoir pour
        un Utilisateur sur la Plateforme </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;; (iv) le num&eacute;ro INPE ; (v) un relev&eacute;
        d&rsquo;identit&eacute; bancaire RIB au nom de l&rsquo;Abonn&eacute;
        dont l&rsquo;&eacute;tablissement financier est domicili&eacute; au
        Maroc ; et (vi) un mandat SEPA d&ucirc;ment
        compl&eacute;t&eacute;.</span
      >
    </p>
    <p class="c66">
      <span class="c4"
        >pour les Abonn&eacute;s personnes morales de droit priv&eacute; :</span
      ><span class="c13">&nbsp;</span
      ><span class="c4"
        >(i) copie d&rsquo;une pi&egrave;ce d&rsquo;identit&eacute; officielle
        en cours de validit&eacute; du repr&eacute;sentant l&eacute;gal ; (ii)
        un extrait original d&rsquo;inscription au Registre du Commerce et des
        Soci&eacute;t&eacute;s datant de moins de trois (3) mois ; (iii) tout
        dipl&ocirc;me d&rsquo;Etat justifiant d&rsquo;un titre, d&rsquo;une
        qualit&eacute; ou d&rsquo;une sp&eacute;cialisation que
        l&rsquo;Abonn&eacute; souhaite faire valoir pour un Utilisateur sur la
        Plateforme </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;; (certificat d&rsquo;Inscription au Registre de Commerce, son
        ICE, et son RC ;</span
      >
    </p>
    <p class="c45">
      <span class="c4"
        >(iii) tout dipl&ocirc;me d&rsquo;Etat justifiant d&rsquo;un titre,
        d&rsquo;une qualit&eacute; ou d&rsquo;une sp&eacute;cialisation dont
        l&rsquo;Abonn&eacute; souhaite se pr&eacute;valoir ou faire valoir pour
        un Utilisateur sur la Plateforme </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;; (iv) le num&eacute;ro INPE ;(v) un relev&eacute;
        d&rsquo;identit&eacute; bancaire RIB au nom de l&rsquo;Abonn&eacute;
        dont l&rsquo;&eacute;tablissement financier est domicili&eacute; au
        Maroc ; et (vi) un mandat SEPA d&ucirc;ment
        compl&eacute;t&eacute;.</span
      >
    </p>
    <p class="c74 c25"><span class="c47"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="5">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span>VALIDATION DE L&rsquo;ABONNEMENT</span>
        </h1>
      </li>
    </ol>
    <p class="c102">
      <span class="c3"
        >L&rsquo;acceptation du Contrat s&rsquo;effectue en ligne par Signature
        &Eacute;lectronique. Toute Signature &Eacute;lectronique de
        l&rsquo;Abonn&eacute; constitue une acceptation irr&eacute;vocable du
        document concern&eacute; et a valeur l&eacute;gale. Le fait de
        s&rsquo;abonner en ligne implique l&rsquo;adh&eacute;sion pleine et
        enti&egrave;re de l&rsquo;Abonn&eacute; au Contrat.</span
      >
    </p>
    <p class="c115">
      <span class="c4"
        >L&rsquo;acceptation en ligne du Contrat par l&rsquo;Abonn&eacute;
        suivie par la confirmation par </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;de l&rsquo;Abonnement par courriel &agrave; l&rsquo;Abonn&eacute;
        valent formation du Contrat.</span
      >
    </p>
    <p class="c115">
      <span class="c4"
        >L&rsquo;Abonn&eacute; doit fournir l&#39;int&eacute;gralit&eacute; des
        pi&egrave;ces justificatives vis&eacute;es &agrave; l&rsquo;article 4.
        &laquo; Informations relatives &agrave; l&rsquo;Abonn&eacute; &raquo; au
        moment de la conclusion du Contrat et au plus tard dans un un
        d&eacute;lai de dix(10) jours &agrave; compter de toute notification de </span
      ><span class="c13">dok.ma</span
      ><span class="c4"
        >&nbsp;de fournir l&rsquo;ensemble des pi&egrave;ces justificatives. A
        d&eacute;faut de transmission par l&rsquo;Abonn&eacute; &agrave; </span
      ><span class="c13">dok.ma</span
      ><span class="c4"
        >&nbsp;des pi&egrave;ces justificatives &agrave; l&rsquo;expiration de
        ce d&eacute;lai, </span
      ><span class="c13">dok.ma</span
      ><span class="c4"
        >&nbsp;adressera &agrave; l&#39;Abonn&eacute; par tout moyen une
        derni&egrave;re relance. En l&rsquo;absence de toute fourniture des
        pi&egrave;ces manquantes par l&rsquo;Abonn&eacute; dans un d&eacute;lai
        de huit (8) jours apr&egrave;s relance, le Contrat sera
        r&eacute;sili&eacute; de plein droit, les redevances d&rsquo;Abonnement
        du</span
      ><span class="c13">&nbsp;</span
      ><span class="c3">mois en cours restant dues.</span>
    </p>
    <p class="c107">
      <span class="c4"
        >L&#39;Abonn&eacute; n&#39;aura un acc&egrave;s effectif aux Services </span
      ><span class="c13">dok.ma</span
      ><span class="c4"
        >&nbsp;que lorsque toutes les pi&egrave;ces justificatives auront
        &eacute;t&eacute; transmises &agrave; </span
      ><span class="c13">dok.ma</span
      ><span class="c3"
        >&nbsp;dans les d&eacute;lais d&eacute;crits ci-dessus.</span
      >
    </p>
    <p class="c25 c107"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="6">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span>EMP&Ecirc;CHEMENT &Agrave; L&rsquo;ABONNEMENT</span>
        </h1>
      </li>
    </ol>
    <p class="c34">
      <span class="c13">dok.ma</span
      ><span class="c4"
        >&nbsp;se r&eacute;serve le droit de refuser l&rsquo;Abonnement &agrave;
        toute personne d&eacute;bitrice de </span
      ><span class="c13">dok.ma</span
      ><span class="c4"
        >&nbsp;au titre d&rsquo;autres contrats, sauf pour les cr&eacute;ances
        faisant l&rsquo;objet d&rsquo;une contestation s&eacute;rieuse. Est
        consid&eacute;r&eacute;e comme s&eacute;rieuse toute contestation de
        cr&eacute;ance &eacute;tay&eacute;e par des &eacute;l&eacute;ments de
        fait et/ou de droit et adress&eacute;e par lettre recommand&eacute;e
        avec avis de r&eacute;ception au Service Clients </span
      ><span class="c13">dok.ma</span><span class="c3">.</span>
    </p>
    <p class="c34 c25"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="7">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span class="c1"
            >MODIFICATION DE LA SITUATION DE L&rsquo;ABONN&Eacute;</span
          >
        </h1>
      </li>
    </ol>
    <p class="c85 c98">
      <span class="c3"
        >En cas de changement de domicile, si&egrave;ge social, adresse
        &eacute;lectronique, domiciliation bancaire (pour le paiement par
        pr&eacute;l&egrave;vement automatique) ou de modification substantielle
        de la situation de l&rsquo;Abonn&eacute; (suspension et/ou retrait du
        droit d&rsquo;exercer, ouverture de proc&eacute;dure collective,
        cessation d&rsquo;activit&eacute;), l&rsquo;Abonn&eacute; s&rsquo;engage
        &agrave; en informer sans d&eacute;lai le Service Clients par
        notification &agrave; travers la Plateforme dok.ma et son formulaire de
        contact.</span
      >
    </p>
    <p class="c5"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="8">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span class="c1">MODIFICATION DE L&rsquo;ABONNEMENT</span>
        </h1>
      </li>
    </ol>
    <p class="c54">
      <span class="c3"
        >L&rsquo;Abonn&eacute; est responsable de la cr&eacute;ation et du suivi
        des Profils Utilisateurs et des Agendas. L&rsquo;identit&eacute; de
        l&rsquo;Utilisateur ainsi que le nombre d&rsquo;Utilisateurs et
        d&rsquo;agendas peuvent &eacute;voluer sans aucune contrainte.
        L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; en informer dok.ma</span
      >
    </p>
    <p class="c49">
      <span class="c3"
        >(i) par courrier simple, ou (ii) par notification &agrave; travers la
        Plateforme dok.ma et son formulaire de contact.</span
      >
    </p>
    <p class="c18">
      <span class="c3"
        >Toute demande de cr&eacute;ation de compte Utilisateur ou
        d&rsquo;Agenda apr&egrave;s la Date d&#39;entr&eacute;e en vigueur est
        assujettie aux conditions &eacute;nonc&eacute;es dans le Contrat,
        &agrave; moins que les Parties n&#39;en conviennent autrement par
        &eacute;crit.</span
      >
    </p>
    <p class="c18 c25"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="9">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span>OBLIGATIONS, RESPONSABILIT&Eacute;S ET GARANTIES DE</span
          ><span>&nbsp;</span><span class="c24">dok.ma</span>
        </h1>
      </li>
    </ol>
    <p class="c85 c86">
      <span class="c3"
        >dok.ma met en place les moyens et les mesures n&eacute;cessaires
        &agrave; la bonne marche et au maintien de la continuit&eacute; et de la
        qualit&eacute; des Services.</span
      >
    </p>
    <p class="c85 c111">
      <span class="c3"
        >L&#39;Abonn&eacute; reconna&icirc;t que le r&ocirc;le de dok.ma se
        limite &agrave; celui d&rsquo;un simple interm&eacute;diaire et
        prestataire technique.</span
      >
    </p>
    <p class="c78">
      <span class="c3"
        >L&rsquo;Abonn&eacute; reconna&icirc;t que dok.ma ne peut &ecirc;tre
        tenue responsable d&rsquo;une interruption de Services ou d&rsquo;un
        retard d&rsquo;ex&eacute;cution hors de son contr&ocirc;le et en
        particulier que la fourniture des Services d&eacute;pend de la
        fiabilit&eacute;, de la disponibilit&eacute; et de la continuit&eacute;
        de connexions d&rsquo;un certain nombre de parties tierces (exploitants
        du r&eacute;seau des t&eacute;l&eacute;communications, l&rsquo;Internet
        public, les &Eacute;quipements de l&rsquo;Abonn&eacute; et de ses
        Utilisateurs, etc.) ainsi que de l&rsquo;exactitude et de
        l&rsquo;int&eacute;grit&eacute; des informations fournies par
        l&rsquo;Abonn&eacute; et de ses Utilisateurs. dok.ma pourra &ecirc;tre
        amen&eacute;e &agrave; suspendre tout ou partie des Services &agrave;
        des fins de maintenance programm&eacute;e par dok.ma ou l&rsquo;un de
        ses Sous-traitants ou en cas d&rsquo;imp&eacute;ratif technique
        (maintenance d&rsquo;urgence).</span
      >
    </p>
    <p class="c28">
      <span class="c3"
        >dok.ma met &agrave; la disposition de l&rsquo;Abonn&eacute; et de ses
        Utilisateurs un</span
      >
    </p>
    <p class="c30">
      <span class="c3"
        >Il est entendu entre les Parties que dok.ma ne saurait en aucun cas
        &ecirc;tre tenue responsable de r&eacute;parer d&rsquo;&eacute;ventuels
        dommages indirects subis par l&rsquo;Abonn&eacute; et/ou ses
        Utilisateurs &agrave; l&rsquo;occasion de l&rsquo;utilisation des
        Services, tels que (i) les dommages qui ne r&eacute;sultent pas
        exclusivement et directement de la d&eacute;faillance des Services de
        dok.ma et notamment les pertes d&rsquo;exploitation, de
        patient&egrave;le, de contrats, de gains ou d&rsquo;&eacute;conomies
        anticip&eacute;es, de chiffre d&rsquo;affaires ; (ii) l&rsquo;atteinte
        &agrave; l&rsquo;honneur et &agrave; la r&eacute;putation.</span
      >
    </p>
    <p class="c38">
      <span class="c3"
        >En outre, la responsabilit&eacute; de dok.ma ne saura &ecirc;tre
        engag&eacute;e pour des actes (i) r&eacute;alis&eacute;s par
        l&rsquo;Abonn&eacute;, un Utilisateur ou un tiers &nbsp;utilisant
        &nbsp;les &nbsp;Services &nbsp;ou &nbsp;agissant &nbsp;sur
        &nbsp;instruction &nbsp;de l&rsquo;Abonn&eacute; ou de ses Utilisateurs
        ou (ii) r&eacute;sultant d&rsquo;une n&eacute;gligence de
        l&rsquo;Abonn&eacute; ou de ses Utilisateurs ou (iii) qui ne seraient
        pas conformes &agrave; la r&eacute;glementation applicable et/ou au
        Contrat. dok.ma d&eacute;cline toute responsabilit&eacute; en cas de
        litige, quelle qu&rsquo;en soit la cause, entre un Utilisateur et un
        Patient, non imputable &agrave; dok.ma.</span
      >
    </p>
    <p class="c6">
      <span class="c3"
        >En toute hypoth&egrave;se, en cas de faute prouv&eacute;e de dok.ma
        &agrave; l&rsquo;&eacute;gard de l&rsquo;Abonn&eacute;,
        l&rsquo;enti&egrave;re responsabilit&eacute; de dok.ma et de ses
        repr&eacute;sentants envers l&rsquo;Abonn&eacute;, ses
        repr&eacute;sentants, Utilisateurs et employ&eacute;s, au titre du
        Contrat, est limit&eacute;e, tous dommages et p&eacute;nalit&eacute;s
        confondus, &agrave; un montant &eacute;quivalent &agrave; douze (12)
        mois d&rsquo;Abonnement du/des Services pr&eacute;c&eacute;dant le fait
        g&eacute;n&eacute;rateur du dommage.</span
      >
    </p>
    <p class="c85 c89">
      <span class="c3"
        >Toute limitation de responsabilit&eacute; mentionn&eacute;e dans le
        Contrat ne saurait trouver application lorsqu&rsquo;elle doit &ecirc;tre
        &eacute;cart&eacute;e en application de la loi et notamment en cas de
        (i) d&eacute;c&egrave;s ou atteinte grave &agrave;
        l&rsquo;int&eacute;grit&eacute; physique ; (ii) faute lourde ; ou (iii)
        dol.</span
      >
    </p>
    <p class="c62">
      <span class="c3"
        >Les Parties s&rsquo;efforceront de r&eacute;aliser les objectifs
        mentionn&eacute;s dans le Contrat. En particulier, la Partie qui souffre
        de l&rsquo;inex&eacute;cution d&rsquo;une obligation doit prendre toutes
        les mesures raisonnables pour r&eacute;duire au minimum le
        pr&eacute;judice susceptible d&rsquo;en r&eacute;sulter. Faute
        d&rsquo;agir de la sorte, elle ne peut obtenir de l&rsquo;autre que la
        r&eacute;paration du pr&eacute;judice qu&rsquo;elle ne pouvait
        &eacute;viter.</span
      >
    </p>
    <p class="c25 c74"><span class="c20"></span></p>
    <p class="c70">
      <span class="c3"
        >Les Parties d&eacute;clarent que (i) les prix convenus au Contrat
        tiennent compte de la r&eacute;partition du risque tel que
        n&eacute;goci&eacute; entre les Parties ; (ii) la limitation de
        responsabilit&eacute; mentionn&eacute;e ci-dessus est une condition
        substantielle &agrave; la signature du Contrat.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-0" start="10">
      <li class="c131 li-bullet-0">
        <h1 id="h.hxvm51sfovq0" style="display:inline">
          <span class="c1">OBLIGATION DE L&#39;ABONN&Eacute;</span>
        </h1>
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c15 li-bullet-1">
        <span class="c51 c52"
          >L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; :</span
        >
      </li>
    </ol>
    <p class="c50 c25"><span class="c11"></span></p>
    <ol class="c9 lst-kix_list_3-0 start" start="1">
      <li class="c26">
        <span class="c3"
          >communiquer &agrave; dok.ma, dans les d&eacute;lais convenus, toutes
          les informations n&eacute;cessaires &agrave; la fourniture des
          Services et &agrave; actualiser par &eacute;crit ces informations.
          L&rsquo;Abonn&eacute; est seul responsable des cons&eacute;quences
          li&eacute;es &agrave; un d&eacute;faut ou un retard
          d&rsquo;actualisation desdites informations ;</span
        >
      </li>
      <li class="c26">
        <span class="c3"
          >v&eacute;rifier que le syst&egrave;me informatique et les
          &Eacute;quipements n&eacute;cessaires &agrave; l&rsquo;utilisation des
          Services et utilis&eacute;s par l&rsquo;Abonn&eacute; et ses
          Utilisateurs sont conformes aux exigences techniques qui lui ont
          &eacute;t&eacute; communiqu&eacute;es. est responsable de son
          raccordement au r&eacute;seau Internet aupr&egrave;s d&rsquo;un
          fournisseur d&rsquo;acc&egrave;s &agrave; internet et du choix du
          navigateur Internet compatible avec la Plateforme dok.ma.
          L&rsquo;acquisition, l&rsquo;installation et la maintenance des
          &Eacute;quipements ainsi que les frais de communications
          &eacute;lectroniques (tels que les co&ucirc;ts
          t&eacute;l&eacute;phoniques et les co&ucirc;ts d&rsquo;acc&egrave;s
          &agrave; Internet) r&eacute;sultant de leur utilisation sont &agrave;
          la charge exclusive de l&rsquo;Abonn&eacute; et de ses Utilisateurs.
          Il est de la responsabilit&eacute; de l&rsquo;Abonn&eacute; de
          s&rsquo;informer du prix d&rsquo;utilisation desdits
          &Eacute;quipements et services aupr&egrave;s des op&eacute;rateurs
          concern&eacute;s ;</span
        >
      </li>
      <li class="c26">
        <span class="c3"
          >se pr&eacute;munir contre les risques de perte ou de</span
        >
      </li>
      <li class="c26">
        <span class="c3"
          >piratage de donn&eacute;es, fichiers et programmes en utilisant des
          progiciels antivirus r&eacute;guli&egrave;rement mis &agrave; jour
          ;</span
        >
      </li>
      <li class="c26">
        <span class="c3"
          >restreindre l&rsquo;acc&egrave;s et observer la plus grande
          confidentialit&eacute; s&rsquo;agissant des modes d&rsquo;acc&egrave;s
          aux Services, quels qu&rsquo;ils soient, et de mani&egrave;res
          g&eacute;n&eacute;rales, &agrave; s&eacute;curiser modes
          d&rsquo;acc&egrave;s afin d&rsquo;emp&ecirc;cher une utilisation non
          autoris&eacute;e des Services ;</span
        >
      </li>
      <li class="c26">
        <span class="c3"
          >ne pas communiquer &agrave; dok.ma des Donn&eacute;es &agrave;
          caract&egrave;re personnel lors de phases de test de connecteur
          permettant d&rsquo;&eacute;tablir une interop&eacute;rabilit&eacute;
          avec un autre logiciel ou une autre application ;</span
        >
      </li>
      <li class="c26">
        <span class="c3"
          >utiliser les Services dans le respect (a) de la l&eacute;gislation
          marocaine en vigueur, notamment en mati&egrave;re de communication sur
          Internet, de protection des Donn&eacute;es &agrave; caract&egrave;re
          personnel, de protection de la Propri&eacute;t&eacute; Intellectuelle
          et du droit &agrave; l&rsquo;image ; (b) du Code de la sant&eacute;
          publique et (c) du code de d&eacute;ontologie auquel il est soumis ;
          (d) assurer la disponibilit&eacute; et la coop&eacute;ration des
          Utilisateurs intervenant dans le cadre de l&#39;ex&eacute;cution du
          Contrat.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c15 li-bullet-1">
        <span class="c3">Par ailleurs, l&rsquo;Abonn&eacute; :</span>
      </li>
    </ol>
    <p class="c32 c25"><span class="c20"></span></p>
    <ol class="c9 lst-kix_list_2-0 start" start="1">
      <li class="c39 c49">
        <span class="c3"
          >est responsable (a) de l&rsquo;utilisation des Services par les
          Utilisateurs ; (b) des Donn&eacute;es Abonn&eacute;s et de leur
          utilisation par les Utilisateurs et les Patients ; (c) de
          l&rsquo;exploitation et de la mise &agrave; jour desdites
          Donn&eacute;es Abonn&eacute;.</span
        >
      </li>
      <li class="c39 c64">
        <span class="c3"
          >garantit dok.ma et ses repr&eacute;sentants contre toute
          r&eacute;clamation, action ou demande d&rsquo;indemnisation d&rsquo;un
          Patient, d&rsquo;un internaute, d&rsquo;un Utilisateur, d&rsquo;un
          tiers ou d&rsquo;une autorit&eacute; publique ; et</span
        >
      </li>
      <li class="c19">
        <span class="c3"
          >le cas &eacute;ch&eacute;ant, indemnise dok.ma et ses
          repr&eacute;sentants de tous les frais (y compris les honoraires,
          frais et d&eacute;pens de justice) et dommages et
          int&eacute;r&ecirc;ts li&eacute;s aux r&eacute;clamations et actions
          en justice se rapportant (a) &agrave; l&rsquo;utilisation des Services
          par les Utilisateurs de mani&egrave;re ill&eacute;gale, frauduleuse,
          ou non conforme au Contrat ou &agrave; la d&eacute;ontologie des
          Utilisateurs le cas &eacute;ch&eacute;ant ; (b) aux Donn&eacute;es
          Abonn&eacute; et Utilisateur et leur utilisation par les Utilisateurs
          et les Patients ; (c) &agrave; la violation de droits de
          Propri&eacute;t&eacute; Intellectuelle de dok.ma ainsi que de tout
          tiers, du fait de l&rsquo;utilisation des Services par tout
          Utilisateur.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c31">
        <span class="c3"
          >Clause de porte-fort : l&rsquo;Abonn&eacute; se porte fort de ce que
          l&rsquo;Utilisateur respecte toutes les obligations mises &agrave; sa
          charge au titre du Contrat en sa qualit&eacute; d&rsquo;Utilisateur
          des Services.</span
        >
      </li>
    </ol>
    <p class="c25 c85 c110"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="2">
      <li class="c68 c39 c85">
        <span class="c3"
          >L&rsquo;Abonn&eacute; est seul responsable des cons&eacute;quences
          et/ou dommages li&eacute;s &agrave; toute int&eacute;gration, non
          effectu&eacute;e par dok.ma, de services/logiciels tiers sur la
          Plateforme dok.ma. L&rsquo;Abonn&eacute; reconna&icirc;t que dok.ma ne
          peut &ecirc;tre tenue responsable des cons&eacute;quences et/ou
          dommages li&eacute;s, en tout ou partie, &agrave;
          l&rsquo;int&eacute;gration mentionn&eacute;e ci-dessus. Par ailleurs,
          dans le cadre de la mise &agrave; disposition d&rsquo;un connecteur
          entre la Plateforme dok.ma et un logiciel m&eacute;dical non fourni
          par dok.ma, dok.ma n&rsquo;est pas responsable des dommages qui
          pourraient r&eacute;sulter des d&eacute;veloppements dont elle
          n&rsquo;est pas propri&eacute;taire (qui seraient par exemple
          entrepris ou conc&eacute;d&eacute;s en licence par
          l&rsquo;&eacute;diteur d&rsquo;un logiciel m&eacute;dical
          lui-m&ecirc;me).</span
        >
      </li>
    </ol>
    <p class="c25 c68"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="3">
      <li class="c19">
        <span class="c3"
          >dok.ma recommande fortement l&#39;utilisation du module de prise de
          rendez-vous en ligne en combinaison avec les autres modules du Service
          d&rsquo;Agenda. En cas d&rsquo;utilisation partielle du Service
          d&rsquo;Agenda ne permettant pas &agrave; dok.ma d&rsquo;assurer un
          service optimal et une satisfaction des Utilisateurs, du fait
          notamment du risque de cr&eacute;ation de doublon de rendez-vous,
          dok.ma se r&eacute;serve la possibilit&eacute; de suspendre et/ou
          r&eacute;silier le Contrat, dans les conditions des articles 15 et
          16.</span
        >
      </li>
    </ol>
    <p class="c50 c25 c85 c90"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="4">
      <li class="c36">
        <span class="c3"
          >Tout Abonn&eacute; ayant souscrit &agrave; un Service de
          T&eacute;l&eacute;consultation reconna&icirc;t &ecirc;tre
          inform&eacute; que le Service de T&eacute;l&eacute;consultation a
          vocation &agrave; &ecirc;tre utilis&eacute; par les Acteurs de
          sant&eacute; exer&ccedil;ant leur activit&eacute; sur le territoire
          marocain et &eacute;tant le cas &eacute;ch&eacute;ant inscrit au
          Tableau de l&rsquo;Ordre auquel leur profession est attach&eacute;e.
          La responsabilit&eacute; de dok.ma ne saurait &ecirc;tre
          recherch&eacute;e pour des actes r&eacute;alis&eacute;s en
          m&eacute;connaissance de cette stipulation. dok.ma ne peut
          v&eacute;rifier la localisation et le lieu de r&eacute;sidence des
          Utilisateurs et des Patients.</span
        >
      </li>
    </ol>
    <p class="c25 c41"><span class="c47"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="11">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span class="c1"
            >PRIX &ndash; FACTURATION &ndash; MODALIT&Eacute;S DE PAIEMENT</span
          >
        </h1>
      </li>
    </ol>
    <p class="c32 c25"><span class="c76 c109"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="5">
      <li class="c104 li-bullet-2"><span class="c3">Prix</span></li>
    </ol>
    <p class="c50 c25"><span class="c76 c69"></span></p>
    <p class="c40">
      <span class="c13"
        >L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; payer &agrave; dok.ma les
        Co&ucirc;ts de Services figurant sur les factures &eacute;lectroniques.
        Les Co&ucirc;ts de Services figurent dans le compte de
        l&rsquo;Abonn&eacute; en version imprimable sur le site </span
      ><span class="c24 c13">pro.dok.ma</span><span class="c3">.</span>
    </p>
    <p class="c84">
      <span class="c3"
        >Les factures sont payables en dirhams et toutes taxes comprises
        (TTC).</span
      >
    </p>
    <p class="c50 c25"><span class="c51 c69"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="6">
      <li class="c50 c93 li-bullet-3">
        <h1 style="display:inline">
          <span class="c3">Modification des prix</span>
        </h1>
      </li>
    </ol>
    <p class="c85 c116">
      <span class="c3"
        >dok.ma peut faire &eacute;voluer tout ou partie des Co&ucirc;ts de
        Services. Les modifications peuvent &ecirc;tre applicables &agrave; tous
        les contrats, y compris ceux en cours d&rsquo;ex&eacute;cution. Dans ce
        cas, l&rsquo;Abonn&eacute; en est inform&eacute; par tout moyen un (1)
        mois avant l&rsquo;entr&eacute;e en vigueur des nouveaux Co&ucirc;ts de
        Services. Si l&rsquo;Abonn&eacute; b&eacute;n&eacute;ficie d&rsquo;un
        Abonnement annuel, le changement de prix interviendra au terme de la
        p&eacute;riode pour laquelle il s&rsquo;est acquitt&eacute; de
        l&rsquo;Abonnement.</span
      >
    </p>
    <p class="c6">
      <span class="c3"
        >L&rsquo;Abonn&eacute;, s&rsquo;il refuse cette augmentation, sera en
        droit de r&eacute;silier par lettre recommand&eacute;e avec
        accus&eacute; de r&eacute;ception, le ou les Services impact&eacute;s
        sans aucune p&eacute;nalit&eacute;, dans un d&eacute;lai de quinze (15)
        jours &agrave; compter de la date de r&eacute;ception de ladite
        notification. La r&eacute;siliation prendra effet &agrave; la date
        d&rsquo;application des nouveaux tarifs.</span
      >
    </p>
    <p class="c32 c25"><span class="c20"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="7">
      <li class="c124 c130 li-bullet-4">
        <h1 style="display:inline"><span class="c3">Facturation</span></h1>
      </li>
    </ol>
    <p class="c90 c85 c127">
      <span class="c3"
        >La facture est transmise &agrave; l&rsquo;Abonn&eacute; par voie
        &eacute;lectronique via le compte de l&#39;Abonn&eacute; et par email
        (i) mensuellement pour les Abonnements mensuels, (ii) annuellement pour
        les Abonnements annuels si l&rsquo;Abonn&eacute; y est &eacute;ligible.
        Pour tout Abonnement annuel, la facture sera &eacute;mise au cours du
        premier mois d&rsquo;Abonnement de la p&eacute;riode annuelle en
        cours.</span
      >
    </p>
    <p class="c37">
      <span class="c3"
        >Il est de la responsabilit&eacute; de l&rsquo;Abonn&eacute;
        d&rsquo;informer imm&eacute;diatement dok.ma de tout ajout
        d&rsquo;Agendas &agrave; son organisation. En cas de non information de
        la part de l&rsquo;Abonn&eacute;, dok.ma pourra demander le paiement
        int&eacute;gral des Co&ucirc;ts de Services de tout nouvel Agenda sur la
        facture du mois suivant la prise de connaissance par dok.ma de cet
        ajout.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="8">
      <li class="c133 li-bullet-5">
        <h1 id="h.8fzj1kbvuf90" style="display:inline">
          <span class="c3">Modalit&eacute;s de paiement</span>
        </h1>
      </li>
    </ol>
    <p class="c23">
      <span class="c3"
        >Les Services sont payables par l&rsquo;Abonn&eacute; &agrave; la date
        indiqu&eacute;e sur la facture, par pr&eacute;l&egrave;vement
        automatique pour le terme &agrave; &eacute;choir.</span
      >
    </p>
    <p class="c85 c105">
      <span class="c3"
        >dok.ma utilise les services de PAYZONE, &eacute;tablissement de
        paiement agr&eacute;&eacute; par la CMI, pour traiter les paiements par
        pr&eacute;l&egrave;vement automatique.</span
      >
    </p>
    <p class="c54">
      <span class="c3"
        >Toute facture non contest&eacute;e dans un d&eacute;lai de trente (30)
        jours de sa date d&rsquo; &eacute;mission est r&eacute;put&eacute;e
        d&ucirc;ment accept&eacute;e par l&rsquo;Abonn&eacute;.
        L&rsquo;Abonn&eacute; ne peut pas se pr&eacute;valoir des
        m&eacute;canismes de compensation ou de d&eacute;duction ni retenir
        toutes sommes dues &agrave; dok.ma en vertu du Contrat.
        L&rsquo;Abonn&eacute; sera redevable de tous les frais de transaction
        bancaire associ&eacute;s au paiement, le cas
        &eacute;ch&eacute;ant.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="9">
      <li class="c108 li-bullet-6">
        <h1 style="display:inline">
          <span class="c3">Incidents de paiement</span>
        </h1>
      </li>
    </ol>
    <p class="c85 c95">
      <span class="c3"
        >Le d&eacute;faut de paiement des factures dans le d&eacute;lai imparti
        entra&icirc;ne, de plein droit et sans qu&rsquo;un rappel soit
        n&eacute;cessaire, l&rsquo;application du taux
        d&#39;int&eacute;r&ecirc;t des p&eacute;nalit&eacute;s de retard,
        exigible le jour suivant la date de r&egrave;glement figurant sur la
        facture.</span
      >
    </p>
    <p class="c29">
      <span class="c3"
        >Ce taux est &eacute;gal au taux d&#39;int&eacute;r&ecirc;t
        appliqu&eacute; par la Banque centrale Marocaine &agrave; son
        op&eacute;ration de refinancement la plus r&eacute;cente major&eacute;
        de dix (10) points de pourcentage, au jour de l&rsquo;exigibilit&eacute;
        de la cr&eacute;ance.</span
      >
    </p>
    <p class="c98 c85">
      <span class="c3"
        >Tout Abonn&eacute; en situation de retard de paiement, pour tout ou
        partie des Services, est de plein droit d&eacute;biteur, &agrave;
        l&#39;&eacute;gard de dok.ma, d&#39;une indemnit&eacute; forfaitaire
        pour frais de recouvrement, d&rsquo;un montant de quatre cents (400)
        dirhams.</span
      >
    </p>
    <p class="c85 c118">
      <span class="c3"
        >Lorsque les frais de recouvrement expos&eacute;s sont sup&eacute;rieurs
        au montant de cette indemnit&eacute; forfaitaire, dok.ma peut demander
        une indemnisation compl&eacute;mentaire, sur justification.</span
      >
    </p>
    <p class="c95 c85">
      <span class="c3"
        >Tout incident de paiement entra&icirc;ne imm&eacute;diatement et sans
        pr&eacute;avis la suspension de l&rsquo;Abonnement et des droits
        d&rsquo;acc&egrave;s aux Services pour l&rsquo;Abonn&eacute; et les
        Utilisateurs concern&eacute;s par cet incident de paiement.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="10">
      <li class="c75 li-bullet-1">
        <h1 style="display:inline">
          <span class="c3">R&eacute;clamations</span>
        </h1>
      </li>
    </ol>
    <p class="c81">
      <span class="c3"
        >Toute r&eacute;clamation doit &ecirc;tre adress&eacute;e en premier
        lieu au Service Clients dok.ma.</span
      >
    </p>
    <p class="c49">
      <span class="c3"
        >dok.ma s&rsquo;engage &agrave; mettre en &oelig;uvre tous les moyens
        n&eacute;cessaires en vue de traiter toute r&eacute;clamation
        adress&eacute;e par l&rsquo;Abonn&eacute; dans les meilleurs
        d&eacute;lais afin de parvenir &agrave; une solution mutuellement
        acceptable pour l&rsquo;Abonn&eacute; et dok.ma. En toute
        hypoth&egrave;se, en cas de contestation portant sur une facture,
        l&rsquo;Abonn&eacute; s&rsquo;engage &agrave; effectuer le
        r&egrave;glement du montant non contest&eacute; de ladite facture.</span
      >
    </p>
    <p class="c25 c50"><span class="c51 c128"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="12">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span class="c1">PROPRI&Eacute;T&Eacute; INTELLECTUELLE</span>
        </h1>
      </li>
    </ol>
    <p class="c30">
      <span class="c3"
        >Les Services de dok.ma et tous les &eacute;l&eacute;ments qui les
        composent sont, sauf mentions particuli&egrave;res, la
        propri&eacute;t&eacute; exclusive de dok.ma.</span
      >
    </p>
    <p class="c85 c125">
      <span class="c3"
        >Aucune stipulation du Contrat ne peut &ecirc;tre
        interpr&eacute;t&eacute;e comme une cession de droits de
        Propri&eacute;t&eacute; Intellectuelle.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="11">
      <li class="c82 li-bullet-7">
        <h1 id="h.irr0m64lybtm" style="display:inline">
          <span class="c3">Droits conc&eacute;d&eacute;s</span>
        </h1>
      </li>
    </ol>
    <p class="c28">
      <span class="c3"
        >dok.ma conc&egrave;de, &agrave; l&rsquo;Abonn&eacute; et ses
        Utilisateurs, pour la dur&eacute;e du Contrat :</span
      >
    </p>
    <ol class="c9 lst-kix_list_1-0 start" start="1">
      <li class="c39 c85 c121">
        <span class="c3"
          >un droit d&rsquo;utilisation personnel, non exclusif, non cessible et
          non transf&eacute;rable de la Plateforme dok.ma.</span
        >
      </li>
      <li class="c36">
        <span class="c3"
          >un droit personnel d&rsquo;utilisation, de reproduction et de
          diffusion de ses marques et/ou logos dans son organisation ou sur ses
          documents professionnels dans le strict but d&rsquo;informer les
          Patients de la possibilit&eacute; de prendre rendez-vous en ligne. Une
          telle exploitation des marques et logos devra &ecirc;tre
          effectu&eacute;e conform&eacute;ment aux instructions, lignes
          directrices et/ou charte d&rsquo;usage des marques et logos de dok.ma.
          Toute autre utilisation, telle que l&rsquo;utilisation de la marque
          dok.ma dans le cadre d&rsquo;un syst&egrave;me publicitaire en ligne,
          pour faire la promotion de ses propres services ou de services tiers
          (type Adwords de Google), est interdite.</span
        >
      </li>
      <li class="c39 c65">
        <span class="c13"
          >un droit d&rsquo;utilisation personnel, non exclusif et non cessible
          et non transf&eacute;rable sur les services vocaux (message et musique
          de pr&eacute;-d&eacute;croch&eacute;, message et musique
          d&rsquo;attente, message et musique pour leur r&eacute;pondeur),
          enregistr&eacute;s par dok.ma et mis &agrave; disposition de
          l&rsquo;Abonn&eacute; et de ses Utilisateurs (soit directement, soit
          au travers de leur t&eacute;l&eacute;secr&eacute;tariat) pour
          r&eacute;orienter les Patients vers le</span
        ><span class="c13">&nbsp;</span><span class="c24 c13">site </span
        ><span class="c13 c24"
          ><a
            class="c55"
            href="https://www.google.com/url?q=http://www.dok.ma.tout/&amp;sa=D&amp;source=editors&amp;ust=1618844048040000&amp;usg=AOvVaw1KIfiScD4FPBwXWRpWbype"
            >www.dok.ma.</a
          ></span
        ><span class="c13"
          ><a
            class="c55"
            href="https://www.google.com/url?q=http://www.dok.ma.tout/&amp;sa=D&amp;source=editors&amp;ust=1618844048040000&amp;usg=AOvVaw1KIfiScD4FPBwXWRpWbype"
            >&nbsp;</a
          ></span
        ><span class="c13"
          ><a
            class="c55"
            href="https://www.google.com/url?q=http://www.dok.ma.tout/&amp;sa=D&amp;source=editors&amp;ust=1618844048041000&amp;usg=AOvVaw2FNa-hoB3-X7ShrVSU11L7"
            >Tout</a
          ></span
        ><span class="c3"
          >&nbsp;autre usage est express&eacute;ment exclu.</span
        >
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c112 li-bullet-5">
        <h1 id="h.c2tlr5q0g2km" style="display:inline">
          <span class="c3">Limitations</span>
        </h1>
      </li>
    </ol>
    <p class="c25 c32"><span class="c57"></span></p>
    <p class="c40">
      <span class="c3"
        >L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; ne pas (i) tenter
        d&rsquo;acc&eacute;der ou copier les codes sources de la Plateforme
        dok.ma ; (ii) utiliser la Plateforme dok.ma &agrave; d&rsquo;autres fins
        que celles d&rsquo;utiliser les Services ; (iii) cr&eacute;er des copies
        de la Plateforme dok.ma ; (iv) reproduire, corriger, extraire, modifier,
        traduire en toutes langues ou tous langages, r&eacute;utiliser,
        arranger, adapter, d&eacute;compiler (&agrave; l&rsquo;exception et dans
        la seule mesure o&ugrave; la loi applicable l&rsquo;autorise de
        mani&egrave;re expresse), ni incorporer la Plateforme dok.ma dans un
        autre logiciel ou cr&eacute;er des travaux d&eacute;riv&eacute;s sur la
        base de la Plateforme dok.ma quels qu&rsquo;en soient le moyen et le
        support ; (v) revendre, louer, ou exploiter commercialement la
        Plateforme dok.ma, ni c&eacute;der/conc&eacute;der la Plateforme dok.ma
        &agrave; un tiers ; (vi) faire des tests d&rsquo;intrusion ou tenter
        d&rsquo;obtenir un d&eacute;ni de service sur les Services.</span
      >
    </p>
    <p class="c8">
      <span class="c3"
        >L&rsquo;Abonn&eacute; reconna&icirc;t que toute violation du
        pr&eacute;sent article constitue un acte de contrefa&ccedil;on
        civilement et p&eacute;nalement sanctionnable.</span
      >
    </p>
    <p class="c84">
      <span class="c3"
        >L&rsquo;Abonn&eacute; comprend et consent que la Plateforme dok.ma est
        une solution SaaS standard fournie &laquo; en l&rsquo;&eacute;tat
        &raquo; &agrave; l&rsquo;Abonn&eacute; et n&rsquo;a pas vocation
        &agrave; r&eacute;pondre &agrave; quelque exigence sp&eacute;cifique de
        celui-ci que ce soit de sa part. dok.ma exclut, dans la mesure o&ugrave;
        la loi le permet, toute garantie de quelque nature que ce soit.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="2">
      <li class="c100 li-bullet-8">
        <h1 id="h.5eumwafk0on4" style="display:inline">
          <span class="c3">Licence</span>
        </h1>
      </li>
    </ol>
    <p class="c23">
      <span class="c3"
        >Tous les logiciels tiers sous licence qui sont int&eacute;gr&eacute;s
        ou non dans la Plateforme dok.ma et/ou aux Services, seront soumis aux
        conditions de licence applicables auxdits logiciels.</span
      >
    </p>
    <p class="c32 c25"><span class="c20"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="13">
      <li class="c16 c39">
        <h1 style="display:inline"><span class="c1">DUR&Eacute;E</span></h1>
      </li>
    </ol>
    <p class="c59 c85">
      <span class="c3"
        >Le Contrat est conclu pour une dur&eacute;e (i) d&rsquo;un (1) mois
        pour un Abonnement mensuel ou (ii) d&rsquo;un (1) an pour un Abonnement
        annuel, &agrave; compter de sa Date d&rsquo;entr&eacute;e en vigueur.
        Les offres de dok.ma &eacute;tant conclues sans p&eacute;riode minimale
        d&#39;abonnement, le Contrat sera tacitement reconduit d&#39;un mois sur
        l&#39;autre et d&rsquo;ann&eacute;e en ann&eacute;e pour les Abonnements
        annuels.</span
      >
    </p>
    <p class="c59 c25 c85"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="14">
      <li class="c16 c39">
        <h1 style="display:inline"><span class="c1">SUSPENSION</span></h1>
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c79 li-bullet-9">
        <span class="c3">Suspension avec pr&eacute;avis</span>
      </li>
    </ol>
    <p class="c54">
      <span class="c3"
        >En cas de (i) non-respect par l&rsquo;Abonn&eacute; ou un Utilisateur
        des stipulations du Contrat ; (ii) incident de paiement ou (iii)
        comportement de l&rsquo;Abonn&eacute; ou d&rsquo;un Utilisateur
        susceptible de porter pr&eacute;judice &agrave; dok.ma ou &agrave;
        l&rsquo;int&eacute;grit&eacute; physique ou mentale des Patients, ou
        (iv) de violation des lois et r&egrave;glements en vigueur, dok.ma
        mettra l&rsquo;Abonn&eacute; et/ou le cas &eacute;ch&eacute;ant
        l&rsquo;Utilisateur en demeure, sur notification envoy&eacute;e par tout
        moyen, de rem&eacute;dier au(x) d&eacute;faut(s) imput&eacute;(s) dans
        un d&eacute;lai de sept (7) jours calendaires suivant la date de
        notification avant de proc&eacute;der de plein droit &agrave; la
        suspension ou &agrave; la limitation d&rsquo;acc&egrave;s de
        l&rsquo;Abonn&eacute; et/ou des Utilisateurs &agrave; tout ou partie des
        Services.</span
      >
    </p>
    <p class="c38">
      <span class="c3"
        >En l&rsquo;absence de correction des d&eacute;fauts ou
        d&rsquo;&eacute;l&eacute;ment de r&eacute;ponse de l&rsquo;Abonn&eacute;
        jug&eacute; satisfaisant par dok.ma dans le d&eacute;lai sept (7) jours
        calendaires apr&egrave;s la suspension ou limitation
        d&rsquo;acc&egrave;s, dok.ma pourra r&eacute;silier tout ou partie du
        Contrat avec effet imm&eacute;diat pour faute.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="2">
      <li class="c71 li-bullet-7">
        <h1 style="display:inline">
          <span class="c3">Suspension sans pr&eacute;avis</span>
        </h1>
      </li>
    </ol>
    <p class="c7">
      <span class="c3"
        >En cas de danger grave ou imminent pour (i) un Patient ou (ii) pour la
        r&eacute;putation de dok.ma, dok.ma pourra suspendre ou limiter
        l&rsquo;acc&egrave;s de l&rsquo;Abonn&eacute; et/ou d&rsquo;un
        Utilisateur, &agrave; tout ou partie des Services, sans pr&eacute;avis.
        L&rsquo;Abonn&eacute; sera inform&eacute; de cette suspension par tout
        moyen. dok.ma d&eacute;cidera unilat&eacute;ralement des suites &agrave;
        donner &agrave; la suspension du Contrat.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="3">
      <li class="c46 li-bullet-7">
        <h1 style="display:inline">
          <span class="c3">Cons&eacute;quences de la suspension</span>
        </h1>
      </li>
    </ol>
    <p class="c12">
      <span class="c3"
        >L&rsquo;Abonn&eacute; pourra r&eacute;cup&eacute;rer les donn&eacute;es
        de sa base patient ainsi que l&rsquo;historique de ses rendez-vous
        pendant la dur&eacute;e de la suspension au moment de la
        r&eacute;siliation effective du service.</span
      >
    </p>
    <p class="c18">
      <span class="c3"
        >La suspension ne lib&egrave;re pas l&rsquo;Abonn&eacute; de son
        obligation de payer les Co&ucirc;ts de Services en vertu du Contrat.
        dok.ma ne sera pas responsable des dommages r&eacute;sultant de la
        suspension des Services en vertu de cet article.</span
      >
    </p>
    <p class="c18 c25"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="15">
      <li class="c16 c39">
        <h1 style="display:inline">
          <span class="c1">R&Eacute;SILIATION</span>
        </h1>
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c94 li-bullet-10">
        <span class="c3">R&eacute;siliation par l&rsquo;Abonn&eacute;</span>
      </li>
    </ol>
    <p class="c29">
      <span class="c3"
        >L&rsquo;Abonn&eacute; peut r&eacute;silier tout ou partie des Services
        sans avoir &agrave; justifier sa d&eacute;cision et sans
        indemnit&eacute;, par lettre recommand&eacute;e avec accus&eacute; de
        r&eacute;ception adress&eacute;e &agrave; dok.ma avec un pr&eacute;avis
        de quinze (15) jours. N&eacute;anmoins, l&rsquo;Abonn&eacute;
        reconna&icirc;t que la r&eacute;siliation du Service d&rsquo;Agenda
        entra&icirc;ne la r&eacute;siliation automatique des Services
        Suppl&eacute;mentaires.</span
      >
    </p>
    <p class="c18">
      <span class="c13"
        >L&rsquo;Abonn&eacute; est inform&eacute; qu&rsquo;en cas de
        r&eacute;siliation de son Contrat, tout mois entam&eacute; reste
        d&ucirc;. Dans le cas d&rsquo;un Abonnement annuel, toute somme
        factur&eacute;e pour la p&eacute;riode annuelle sera rembours&eacute;e
        au </span
      ><span class="c10">prorata temporis </span
      ><span class="c3"
        >pour la partie non consomm&eacute;e de l&rsquo;Abonnement &agrave;
        l&rsquo;exclusion du mois en cours.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="2">
      <li class="c101 li-bullet-7">
        <h1 style="display:inline">
          <span class="c67">R&eacute;siliation par</span
          ><span class="c3">&nbsp;dok.ma</span>
        </h1>
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-2 start" start="1">
      <li class="c56 li-bullet-11">
        <span class="c13"
          >R&eacute;siliation sans faute de l&rsquo;Abonn&eacute;</span
        >
      </li>
    </ol>
    <p class="c29">
      <span class="c13"
        >dok.ma peut r&eacute;silier tout ou partie des Services sans avoir
        &agrave; justifier sa d&eacute;cision et sans indemnit&eacute;, par
        lettre recommand&eacute;e avec accus&eacute; de r&eacute;ception
        adress&eacute;e &agrave; l&rsquo;Abonn&eacute; avec un pr&eacute;avis de
        six (6) mois. En cas d&rsquo;Abonnement annuel, l&rsquo;Abonn&eacute;
        sera rembours&eacute; au </span
      ><span class="c10">prorata temporis </span
      ><span class="c3"
        >pour la partie non consomm&eacute;e de l&rsquo;Abonnement &agrave;
        l&rsquo;exclusion du mois en cours.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-2" start="2">
      <li class="c113 li-bullet-11">
        <span class="c13"
          >R&eacute;siliation pour faute de l&rsquo;Abonn&eacute;</span
        >
      </li>
    </ol>
    <p class="c90 c85 c129">
      <span class="c3"
        >En cas de (i) faute grave de l&rsquo;Abonn&eacute; ou de
        l&rsquo;Utilisateur, telle que l&rsquo;utilisation d&rsquo;un ou
        plusieurs Services (a) contraire aux stipulations du Contrat ; (b)
        contraire aux lois et r&eacute;glementations applicables ; (c) contraire
        &agrave; la d&eacute;ontologie,</span
      >
    </p>
    <p class="c16">
      <span class="c3"
        >(d) susceptible de porter pr&eacute;judice &agrave; la
        r&eacute;putation de dok.ma, ou &agrave; l&rsquo;int&eacute;grit&eacute;
        physique ou mentale ou &agrave; la s&eacute;curit&eacute; des Patients ;
        ou (ii) d&rsquo;incident de paiement, dok.ma pourra r&eacute;silier,
        sans indemnit&eacute; et par tout moyen, tout ou partie des Services,
        avec un pr&eacute;avis de sept (7) jours &agrave; compter de la date de
        suspension effective des Services en application de l&rsquo;Article 15.1
        (ii). L&rsquo;Abonn&eacute; est inform&eacute; qu&rsquo;en cas de
        r&eacute;siliation pour faute de l&rsquo;Abonn&eacute;, tout mois
        entam&eacute; reste d&ucirc;.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-1" start="3">
      <li class="c91 li-bullet-7">
        <h1 style="display:inline">
          <span class="c3">Cons&eacute;quences de la r&eacute;siliation</span>
        </h1>
      </li>
    </ol>
    <p class="c25 c119"><span class="c57"></span></p>
    <p class="c40">
      <span class="c3"
        >La r&eacute;siliation des Services d&rsquo;Agenda, pour quelque raison
        que ce soit, entra&icirc;ne la r&eacute;siliation automatique des
        Services Suppl&eacute;mentaires.</span
      >
    </p>
    <p class="c50 c25"><span class="c48"></span></p>
    <p class="c40">
      <span class="c3"
        >L&rsquo;Abonn&eacute; pourra r&eacute;cup&eacute;rer en format CSV ou
        Excel les donn&eacute;es de sa base patient ainsi que l&rsquo;historique
        de ses rendez-vous h&eacute;berg&eacute;s dans la Plateforme dok.ma
        pendant un d&eacute;lai de trois (3) mois apr&egrave;s la
        r&eacute;siliation effective de l&rsquo;Abonnement.</span
      >
    </p>
    <p class="c32 c25"><span class="c47"></span></p>
    <p class="c16">
      <span class="c3"
        >L&rsquo;Abonn&eacute; reconna&icirc;t avoir tous les droits et/ou
        autorisations n&eacute;cessaires pour pouvoir r&eacute;cup&eacute;rer
        ces donn&eacute;es. Une fois &nbsp;ce d&eacute;lai
        d&eacute;pass&eacute;, dok.ma s&rsquo;engage &agrave; supprimer ou
        anonymiser toutes les Donn&eacute;es Abonn&eacute;. dok.ma est
        n&eacute;anmoins autoris&eacute;e &agrave; conserver une copie des
        Informations Confidentielles pour toutes fins impos&eacute;es par la loi
        &nbsp;applicable.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-0" start="16">
      <li class="c14 li-bullet-12">
        <h1 style="display:inline">
          <span class="c1">CLAUSE D&#39;EXCLUSIVIT&Eacute;</span>
        </h1>
      </li>
    </ol>
    <p class="c23">
      <span class="c3"
        >Pendant toute la dur&eacute;e du Contrat et dans le cadre de
        l&rsquo;exercice de son activit&eacute; sur le territoire marocain,
        l&rsquo;Abonn&eacute; s&#39;interdit d&rsquo;avoir recours &agrave; des
        services concurrents aux Services propos&eacute;s par dok.ma.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-0" start="17">
      <li class="c14 li-bullet-13">
        <h1 style="display:inline">
          <span class="c1"
            >PROTECTION DES DONN&Eacute;ES &Agrave; CARACT&Egrave;RE
            PERSONNEL</span
          >
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c24 c13"
        >L&rsquo;Accord sur la protection des Donn&eacute;es &agrave;
        caract&egrave;re personne</span
      ><span class="c13">l </span
      ><span class="c3"
        >d&eacute;crit les r&ocirc;les et les obligations respectifs de
        l&rsquo;Abonn&eacute; et de dok.ma concernant les Traitements des
        Donn&eacute;es &agrave; caract&egrave;re personnel effectu&eacute;s dans
        le cadre de l&rsquo;ex&eacute;cution des Services. En signant le
        Contrat, l&rsquo;Abonn&eacute; et dok.ma s&rsquo;engagent &agrave;
        respecter les stipulations dudit Accord.</span
      >
    </p>
    <p class="c85 c97">
      <span class="c13"
        >dok.ma met &agrave; disposition de l&rsquo;Abonn&eacute; sur son Site
        une </span
      ><span class="c24 c13">Politique </span
      ><span class="c24 c13">de protection des donn&eacute;es personnelles</span
      ><span class="c13">&nbsp;</span
      ><span class="c3"
        >contenant les informations sur les autres Traitements
        ex&eacute;cut&eacute;s par dok.ma.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-0" start="18">
      <li class="c14 li-bullet-14">
        <h1 style="display:inline">
          <span>TRANSFERT DE DONN&Eacute;ES VERS L&rsquo;APPLICATION</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c3"
        >L&rsquo;Abonn&eacute; autorise express&eacute;ment dok.ma &agrave;
        proc&eacute;der au transfert de toute Donn&eacute;e Abonn&eacute; dont
        il est Responsable de traitement vers la Plateforme dok.ma,
        n&eacute;cessaire pour une parfaite utilisation par lui des Services
        fournis par dok.ma.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-0" start="19">
      <li class="c14 li-bullet-15">
        <h1 style="display:inline">
          <span class="c1">CONFIDENTIALIT&Eacute;</span>
        </h1>
      </li>
    </ol>
    <p class="c77">
      <span class="c3"
        >Chaque Partie garantit assurer la stricte confidentialit&eacute; des
        Informations Confidentielles partag&eacute;es lors des phases
        pr&eacute;contractuelles et post contractuelles, ainsi que pendant
        l&rsquo;ex&eacute;cution du Contrat. A ce titre, chaque Partie
        s&rsquo;engage &agrave; (i) n&rsquo;utiliser les Informations
        Confidentielles qu&rsquo;aux seules fins de l&rsquo;ex&eacute;cution du
        Contrat et dans la stricte mesure du n&eacute;cessaire</span
      >
    </p>
    <p class="c49">
      <span class="c3"
        >; (ii) prendre toutes les mesures de pr&eacute;caution et de protection
        qui s&rsquo;imposent aux fins de pr&eacute;server la
        confidentialit&eacute; des Informations Confidentielles de l&#39;autre
        Partie et d&#39;emp&ecirc;cher l&#39;acc&egrave;s de personnes non
        autoris&eacute;es et, au minimum, leur offrir le m&ecirc;me degr&eacute;
        de protection qu&#39;&agrave; ses propres Informations Confidentielles ;
        (iii) &agrave; ne divulguer ou reproduire les Informations
        Confidentielles de l&#39;autre Partie, qu&rsquo;aux ou pour ses membres,
        employ&eacute;s, pr&eacute;pos&eacute;s ou prestataires (a) qui devront
        avoir acc&egrave;s &agrave; ces Informations Confidentielles pour
        remplir les obligations dont la Partie en question est tenue par le
        Contrat, ou (b) qui ont qualit&eacute; pour en conna&icirc;tre au titre
        du Contrat. Par ailleurs, dok.ma pourra divulguer les termes du Contrat
        (i) &agrave; ses comptables, auditeurs, banques et sources de
        financement et tout autre conseil de dok.ma soumis au secret
        professionnel (ii) aux conseillers ou experts ayant sign&eacute; un
        accord de confidentialit&eacute;.</span
      >
    </p>
    <p class="c43">
      <span class="c3"
        >Dans tous les cas, la Partie destinataire des Informations
        Confidentielles se porte garante du respect de cet engagement de
        confidentialit&eacute; par les personnes ayant connaissance des
        Informations Confidentielles, qu&rsquo;ils s&rsquo;agissent notamment de
        ses employ&eacute;s ou sous-traitants.</span
      >
    </p>
    <p class="c27">
      <span class="c3"
        >L&rsquo;obligation de confidentialit&eacute; restera valable pendant
        une dur&eacute;e de cinq (5) ans apr&egrave;s l&rsquo;expiration, pour
        quelque raison que ce soit, du Contrat. Nonobstant ce qui
        pr&eacute;c&egrave;de, chaque Partie pourra divulguer des Informations
        Confidentielles sans le consentement de l&#39;autre Partie, dans la
        stricte mesure o&ugrave; cette divulgation est requise par une
        autorit&eacute; comp&eacute;tente ou en application d&#39;une obligation
        l&eacute;gale ou d&eacute;ontologique.</span
      >
    </p>
    <ol class="c9 lst-kix_list_5-0" start="20">
      <li class="c117 li-bullet-0">
        <h1 id="h.bldcsae9ryi1" style="display:inline">
          <span class="c1">CESSION DU CONTRAT D&rsquo;ABONNEMENT</span>
        </h1>
      </li>
    </ol>
    <p class="c85 c99">
      <span class="c3"
        >dok.ma se r&eacute;serve le droit de c&eacute;der, transf&eacute;rer ou
        apporter &agrave; un tiers sous quelque forme que ce soit les droits et
        obligations n&eacute;s du Contrat.</span
      >
    </p>
    <p class="c29">
      <span class="c3"
        >L&rsquo;Abonn&eacute; devra obtenir l&rsquo;autorisation
        pr&eacute;alable &eacute;crite de dok.ma pour c&eacute;der ou
        transf&eacute;rer le Contrat.</span
      >
    </p>
    <p class="c41 c25"><span class="c51 c69"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="21">
      <li class="c42 li-bullet-0">
        <h1 id="h.xhi3zu357s0t" style="display:inline">
          <span class="c1">ASSURANCE</span>
        </h1>
      </li>
    </ol>
    <p class="c18">
      <span class="c3"
        >Chacune des Parties s&rsquo;engage &agrave; souscrire, aupr&egrave;s de
        toute compagnie d&rsquo;assurance de son choix notoirement solvable, une
        police d&rsquo;assurance responsabilit&eacute; civile afin de couvrir
        tous les dommages mat&eacute;riels, corporels et/ou immat&eacute;riels
        susceptibles d&rsquo;&ecirc;tre caus&eacute;s directement ou
        indirectement &agrave; l&rsquo;autre Partie et/ou aux tiers, ainsi que
        tous risques sp&eacute;ciaux li&eacute;s &agrave; son activit&eacute;,
        pour toute la dur&eacute;e du Contrat.</span
      >
    </p>
    <p class="c18 c25"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="22">
      <li class="c42 li-bullet-16">
        <h1 id="h.6pmtoz7n0xuw" style="display:inline">
          <span class="c1">FORCE MAJEURE</span>
        </h1>
      </li>
    </ol>
    <p class="c12">
      <span class="c3"
        >De fa&ccedil;on expresse, sont consid&eacute;r&eacute;s par les Parties
        comme des cas de force majeure, outre ceux pr&eacute;sentant les
        crit&egrave;res retenus par la jurisprudence des cours et tribunaux
        marocains, les incendies, les d&eacute;g&acirc;ts des eaux, les
        catastrophes naturelles, les temp&ecirc;tes, les gr&egrave;ves, les
        inondations, les tremblements de terre, les attentats, les explosions,
        les guerres, op&eacute;rations militaires ou troubles &nbsp;civils ou
        les blocages des moyens de transport ou d&rsquo;approvisionnement, dont
        les &eacute;l&eacute;ments constitutifs sont caract&eacute;ris&eacute;s
        au Code des obligations et des contrats et de la jurisprudence de la
        Cour de cassation. En cas de survenance d&rsquo;un tel
        &eacute;v&egrave;nement, la partie victime devra en informer
        imm&eacute;diatement par &eacute;crit l&rsquo;autre Partie.</span
      >
    </p>
    <p class="c12 c25"><span class="c3"></span></p>
    <p class="c72">
      <span class="c13"
        >Le cas de force majeure suspend les obligations de la Partie
        concern&eacute;e pendant le temps o&ugrave; jouera la force majeure.
        N&eacute;anmoins, les Parties s&rsquo;efforceront d&rsquo;en minimiser
        dans toute la mesure du possible les cons&eacute;quences. Si un cas de
        force majeure met l&rsquo;une des Parties dans l&rsquo;incapacit&eacute;
        de remplir ses obligations contractuelles au titre du Contrat pendant
        plus de trente (30) jours cons&eacute;cutifs, l&rsquo;une ou l&#39;autre
        des Parties pourra mettre fin au Contrat apr&egrave;s envoi d&rsquo;un
        courrier recommand&eacute; avec accus&eacute; de r&eacute;ception. Les
        Parties ne seront alors plus tenues au respect de leurs obligations
        &agrave; l&rsquo;exception notamment de celles r&eacute;sultant des
        articles &nbsp; </span
      ><span class="c33">&laquo; </span
      ><span class="c13">Propri&eacute;t&eacute; &nbsp; Intellectuelle </span
      ><span class="c33">&raquo;</span
      ><span class="c13">, &laquo; &nbsp; &nbsp;Confidentialit&eacute; </span
      ><span class="c33">&raquo; &nbsp; </span><span class="c13">et </span
      ><span class="c33">&laquo; </span
      ><span class="c13"
        >Protection &nbsp; des &nbsp; Donn&eacute;es &nbsp; &agrave; &nbsp;
        caract&egrave;re &nbsp; personnel </span
      ><span class="c33">&raquo; &nbsp; &nbsp;</span><span class="c3">des</span>
    </p>
    <p class="c16">
      <span class="c13"
        >pr&eacute;sentes, sans qu&rsquo;aucune indemnit&eacute; ou
        p&eacute;nalit&eacute;, &agrave; quelque titre que ce soit, ne soit due
        de part et d&rsquo;autre.</span
      >
    </p>
    <p class="c50 c25"><span class="c51 c52"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="23">
      <li class="c42 li-bullet-0">
        <h1 id="h.eltpif7pnfih" style="display:inline">
          <span class="c1">DIVERS</span>
        </h1>
      </li>
    </ol>
    <p class="c32 c25"><span class="c76 c92"></span></p>
    <ol class="c9 lst-kix_list_5-1" start="4">
      <li class="c39 c85 c120">
        <span class="c3"
          >Renonciation : le fait, pour l&rsquo;une ou l&rsquo;autre des
          Parties, de ne pas se pr&eacute;valoir d&rsquo;une ou plusieurs
          stipulations du Contrat ne pourra en aucun cas impliquer la
          renonciation par cette Partie &agrave; s&rsquo;en pr&eacute;valoir
          ult&eacute;rieurement.</span
        >
      </li>
      <li class="c39 c85 c122">
        <span class="c3"
          >Nullit&eacute; partielle : dans le cas o&ugrave; certaines
          stipulations du Contrat seraient inapplicables pour quelque raison que
          ce soit, y compris en raison d&rsquo;une loi ou d&rsquo;une
          r&eacute;glementation applicable, les Parties resteront li&eacute;es
          par les autres stipulations du Contrat et s&#39;efforceront de
          rem&eacute;dier aux clauses inapplicables dans le m&ecirc;me esprit
          que celui qui a pr&eacute;sid&eacute; lors de la conclusion.</span
        >
      </li>
      <li class="c2">
        <span class="c3"
          >Convention de preuve : les Parties conviennent express&eacute;ment
          que tout document sign&eacute; de mani&egrave;re
          d&eacute;mat&eacute;rialis&eacute;e dans le cadre du Contrat (i)
          constitue l&rsquo;original dudit document ; (ii) a la m&ecirc;me
          valeur probante qu&rsquo;un &eacute;crit sign&eacute; de fa&ccedil;on
          manuscrite sur support papier et peut valablement &ecirc;tre
          oppos&eacute; aux Parties ; (iii) est susceptible d&rsquo;&ecirc;tre
          produit en justice, &agrave; titre de preuve litt&eacute;rale, en cas
          de litiges. En cons&eacute;quence, les Parties reconnaissent que tout
          document sign&eacute; de mani&egrave;re
          d&eacute;mat&eacute;rialis&eacute;e vaut preuve du contenu dudit
          document, de l&rsquo;identit&eacute; du signataire et de son
          consentement aux obligations et cons&eacute;quences de faits et de
          droit qui d&eacute;coulent du document sign&eacute; de mani&egrave;re
          d&eacute;mat&eacute;rialis&eacute;e. La r&eacute;siliation ou
          l&rsquo;expiration du Contrat ne peut remettre en cause la force
          probante des documents sign&eacute;s de mani&egrave;re
          d&eacute;mat&eacute;rialis&eacute;e avant la date de sa
          r&eacute;siliation/d&rsquo;expiration.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Moyens de preuve : afin d&rsquo;&eacute;tablir l&rsquo;atteinte
          (av&eacute;r&eacute;e ou suppos&eacute;e) &agrave; la
          r&eacute;putation de dok.ma ou &agrave;
          l&rsquo;int&eacute;grit&eacute; physique ou mentale des Patients,
          dok.ma pourra se pr&eacute;valoir de messages de Patients
          envoy&eacute;s &agrave; dok.ma qu&rsquo;elle aura pr&eacute;alablement
          anonymis&eacute;es afin de garantir la confidentialit&eacute; de leurs
          &eacute;changes, tant que le contenu des messages ne permet pas de les
          identifier.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Retours d&rsquo;exp&eacute;riences : si un Abonn&eacute; fournit des
          commentaires &agrave; dok.ma en ce qui concerne la
          fonctionnalit&eacute; ou la performance des Services (incluant
          l&#39;identification d&rsquo;erreurs ou d&rsquo;am&eacute;liorations),
          l&rsquo;Abonn&eacute; s&rsquo;engage &agrave; conc&eacute;der &agrave;
          dok.ma sans restriction ni paiement, tout droit, titre et
          int&eacute;r&ecirc;t pour ceux-ci.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Droits et permissions : chaque Partie d&eacute;clare disposer des
          droits et permissions n&eacute;cessaires pour conclure le Contrat et
          ex&eacute;cuter les obligations qui y sont mentionn&eacute;es.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Ind&eacute;pendance : Il est parfaitement clair entre les Parties que
          chacune d&rsquo;entre elles intervient aux pr&eacute;sentes en tant
          que contractant ind&eacute;pendant et qu&rsquo;aucune des Parties ne
          saurait &ecirc;tre consid&eacute;r&eacute;e comme
          l&rsquo;employ&eacute;, l&rsquo;agent, le distributeur, le mandant ou
          le repr&eacute;sentant de l&rsquo;autre Partie.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Activit&eacute;s professionnelles : l&rsquo;Abonn&eacute; qui
          souscrit un Contrat pour l&rsquo;utilisation des Services
          reconna&icirc;t (i) qu&rsquo;il agit dans le cadre de son
          activit&eacute; professionnelle exerc&eacute;e sur le territoire
          marocain et (ii) que les relations contractuelles r&eacute;gies par le
          Contrat sont encadr&eacute;es par le Code de commerce. En
          cons&eacute;quence, l&rsquo;Abonn&eacute; reconna&icirc;t qu&rsquo;il
          n&rsquo;a ni la qualit&eacute; de consommateur, ni la qualit&eacute;
          de non- professionnel, et qu&rsquo;&agrave; ce titre il ne peut
          b&eacute;n&eacute;ficier des dispositions du Code de la consommation.
          L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; v&eacute;rifier que
          chaque Utilisateur agit dans le cadre de son activit&eacute;
          professionnelle exerc&eacute;e sur le territoire marocain.
          L&rsquo;Abonn&eacute; s&rsquo;engage &agrave; n&rsquo;effectuer aucune
          revente des Services ou &agrave; conc&eacute;der des droits sur les
          Services &agrave; un tiers.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Modification : dok.ma se r&eacute;serve le droit de modifier lesdites
          CA &agrave; tout moment, notamment pour prendre en compte les
          &eacute;volutions l&eacute;gales et/ou r&eacute;glementaires. Cette
          modification entrera en vigueur un (1) mois apr&egrave;s la
          publication des nouvelles dispositions sur la Plateforme dok.ma et le
          Compte Utilisateur. Tout Abonn&eacute; est inform&eacute; que
          l&rsquo;unique version des CA qui fait foi est celle qui se trouve en
          ligne sur son compte Abonn&eacute;, ce qu&rsquo;il reconna&icirc;t et
          accepte sans restriction. L&rsquo;Abonn&eacute; est tenu de se
          r&eacute;f&eacute;rer &agrave; la version en ligne des CA &agrave; la
          date de son acc&egrave;s et de chaque utilisation des Services.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Anticorruption : dans le cadre des activit&eacute;s associ&eacute;es
          au Contrat ou en rapport avec la relation entre les Parties, les
          Parties s&rsquo;engagent &agrave; respecter les dispositions
          l&eacute;gales et r&eacute;glementaires marocaines, ainsi que les
          principes internationalement reconnus relatifs &agrave; la lutte
          contre la corruption.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Version B&ecirc;ta : dok.ma peut proposer &agrave;
          l&rsquo;Abonn&eacute; d&rsquo;essayer les versions b&ecirc;ta de
          certains Services. Ces services b&ecirc;ta sont fournis uniquement
          &agrave; des &nbsp;fins d&#39;&eacute;valuation et ne peuvent
          &ecirc;tre utilis&eacute;s dans un environnement de production.
          L&rsquo;Abonn&eacute; reconna&icirc;t que ces services b&ecirc;ta
          peuvent contenir des bugs, erreurs et autres probl&egrave;mes et
          accepte ceux-ci &laquo; en l&rsquo;&eacute;tat &raquo;, sans garantie
          d&#39;aucune sorte. dok.ma (i) n&rsquo;est pas responsable des
          probl&egrave;mes li&eacute;s &agrave; l&rsquo;utilisation des services
          b&ecirc;ta par les Utilisateurs ; (ii) peut en interrompre
          l&rsquo;utilisation ; (iii) peut supprimer toutes les donn&eacute;es
          contenues dans ces versions b&ecirc;ta, sans aucune
          responsabilit&eacute;.</span
        >
      </li>
      <li class="c22">
        <span class="c13"
          >Test : l&#39;acc&egrave;s aux Services en mode test est soumis
          &agrave; l&rsquo;acceptation pr&eacute;alable par
          l&rsquo;Abonn&eacute; des pr&eacute;sentes Conditions
          d&rsquo;Abonnement. Les services d&#39;essai sont &eacute;galement
          fournis </span
        ><span class="c33">&laquo; </span
        ><span class="c13">en l&rsquo;&eacute;tat </span
        ><span class="c33">&raquo; </span
        ><span class="c13"
          >sans assistance ni aucune garantie expresse ou implicite de quelque
          nature que ce soit. Le testeur s&rsquo;engage &agrave; faire une
          sauvegarde r&eacute;guli&egrave;re des Donn&eacute;es </span
        ><span class="c13">Abonn&eacute;</span
        ><span class="c3"
          >&nbsp;et Utilisateurs fournies &agrave; dok.ma pour le test.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Interpr&eacute;tation : les mots au singulier incluent le pluriel et
          vice versa. Une r&eacute;f&eacute;rence &agrave; un document, une
          norme, une disposition l&eacute;gislative, code ou tout autre document
          implique toute modification ou mise &agrave; jour de ce document,
          norme, disposition l&eacute;gislative ou code. Une quelconque
          r&eacute;f&eacute;rence &agrave; une somme d&#39;argent renvoie
          &agrave; la devise dh.</span
        >
      </li>
    </ol>
    <p class="c83 c25 c87"><span class="c3"></span></p>
    <ol class="c9 lst-kix_list_5-0" start="24">
      <li class="c42 li-bullet-17">
        <h1 id="h.e1xui2rulckx" style="display:inline">
          <span class="c1"
            >R&Egrave;GLEMENT AMIABLE - DROIT APPLICABLE ET ATTRIBUTION DE
            COMPETENCE</span
          >
        </h1>
      </li>
    </ol>
    <ol class="c9 lst-kix_list_5-1 start" start="1">
      <li class="c22">
        <span class="c3"
          >Les pr&eacute;sentes Conditions d&rsquo;Abonnement sont soumises au
          droit marocain.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Avant toute action contentieuse, dok.ma et l&rsquo;Abonn&eacute;
          chercheront, de bonne foi, &agrave; r&eacute;gler &agrave;
          l&rsquo;amiable tous diff&eacute;rends entre eux relatifs &agrave; la
          validit&eacute;, l&rsquo;interpr&eacute;tation,
          l&rsquo;ex&eacute;cution ou l&rsquo;inex&eacute;cution,
          l&rsquo;interruption, la r&eacute;siliation ou la d&eacute;nonciation
          des pr&eacute;sentes Conditions d&rsquo;Abonnement et ce, pour
          quelques causes et sur quelques fondements que ce soient, en
          s&rsquo;&eacute;crivant par Lettre Recommand&eacute;e Accus&eacute;e
          de R&eacute;ception exposant la ou les difficult&eacute;s
          rencontr&eacute;es et les demandes en r&eacute;sultant. dok.ma et
          l&rsquo;Abonn&eacute; devront confronter leurs points de vue et
          effectuer toutes constatations utiles pour leur permettre de trouver
          une solution au conflit qui les oppose.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >dok.ma et l&rsquo;Abonn&eacute; s&rsquo;efforceront de trouver un
          accord amiable dans un d&eacute;lai de soixante (60) jours &agrave;
          compter de la notification par l&rsquo;une d&rsquo;elle de la
          n&eacute;cessit&eacute; d&rsquo;un accord amiable.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Sauf pour pr&eacute;server leur droit d&rsquo;action ou pour
          &eacute;viter un dommage imminent, aucune action judiciaire ne saurait
          &ecirc;tre recevable avant que la pr&eacute;sente proc&eacute;dure de
          r&egrave;glement amiable des litiges pr&eacute;vue ci-avant ne soit
          arriv&eacute;e &agrave; son terme.</span
        >
      </li>
      <li class="c22">
        <span class="c3"
          >Toutes difficult&eacute;s relatives &agrave; la validit&eacute;,
          l&rsquo;application ou &agrave; l&rsquo;interpr&eacute;tation du
          Contrat seront soumises, &agrave; d&eacute;faut d&rsquo;accord amiable
          entre dok.ma et l&rsquo;Abonn&eacute;, aux Tribunaux comp&eacute;tents
          dans le ressort de la Cour d&rsquo;appel de Marrakech, auquel dok.ma
          et l&rsquo;Abonn&eacute; attribuent comp&eacute;tence territoriale,
          quel que soit le lieu d&rsquo;ex&eacute;cution ou le domicile du
          d&eacute;fendeur. Cette attribution de comp&eacute;tence
          s&rsquo;applique &eacute;galement en cas de proc&eacute;dure en
          r&eacute;f&eacute;r&eacute;, de pluralit&eacute; de d&eacute;fendeurs
          ou d&rsquo;appel en garantie.</span
        >
      </li>
    </ol>
    <p class="c25 c59"><span class="c3"></span></p>
    <p class="c50 c25 c90"><span class="c3"></span></p>
    <p class="c25 c106"><span class="c3"></span></p>
    <p class="c25 c96"><span class="c3"></span></p>
    <p class="c59 c25"><span class="c3"></span></p>
    <h1 class="c132"><span class="c1"></span></h1>
    <p class="c25 c83"><span class="c3"></span></p>
    <p class="c83 c25"><span class="c3"></span></p>
    <p class="c17"><span class="c3"></span></p>
    <p class="c59 c25"><span class="c3"></span></p>
    <p class="c41 c25"><span class="c11"></span></p>
    <p class="c41 c25"><span class="c11"></span></p>
    <div>
      <p class="c25 c53"><span class="c35"></span></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
